import ConvuRouter from './backend/Routes'
import { AuthProvider } from "./backend/AuthContext";
import { ThemeProvider } from "./backend/ThemeContext";
import { AlertProvider } from "./backend/AlertContext";

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import './styles.css';
import { Profiler } from 'react';

export default function App() {

  /*
  const onRender = function(
      id, // the "id" prop of the Profiler tree that has just committed
      phase, // either "mount" (if the tree just mounted) or "update" (if it re-rendered)
      actualDuration, // time spent rendering the committed update
      baseDuration, // estimated time to render the entire subtree without memoization
      startTime, // when React began rendering this update
      commitTime, // when React committed this update
      interactions // the Set of interactions belonging to this update
    ) {
      console.log(`id: ${id}`);
      console.log(`phase: ${phase}`);
      console.log(`actualDuration: ${actualDuration}`);
      console.log(`baseDuration: ${baseDuration}`);
      console.log(`startTime: ${startTime}`);
      console.log(`commitTime: ${commitTime}`);
      console.log(`interactions: ${interactions}`);
  };

  <Profiler id="App" onRender={onRender}>
  </Profiler>
  */

  return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <AuthProvider>
          <ThemeProvider>
            <AlertProvider>
              <ConvuRouter />
            </AlertProvider>
          </ThemeProvider>
        </AuthProvider>
      </LocalizationProvider>
  );
}


