import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import {useState, useEffect} from 'react';

export default function SnackbarAlert({data}) {
  const [visible, setVisible] = useState(data.open);

  useEffect(() => {
    setVisible(data.open);
  }, [data]);
  
  return (
    <Snackbar open={visible} autoHideDuration={4000} onClose={() => setVisible(false)}>
      <Alert
        severity={data.type}
        variant="filled"
        sx={{ width: '100%' }}
      >
        {data.message}
      </Alert>
    </Snackbar>
  );
};