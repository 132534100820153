import { createContext, useState, useContext } from 'react';

const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
  const [snackbar, setSnackbar] = useState({
    message: '',
    type: 'info', // 'info', 'success', 'warning', 'error'
    open: false,
  });

  const showSnackbar = (message, type = 'info') => {
    setSnackbar({ message, type, open: true });
  };

  const hideSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <AlertContext.Provider value={{ snackbar, showSnackbar, hideSnackbar }}>
      {children}
    </AlertContext.Provider>
  );
};

export const useSnackbar = () => useContext(SnackbarContext);