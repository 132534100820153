import axios from "axios";
import {auth} from "./firebaseInit";

const getUserToken = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(async (auth_user) => {
      unsubscribe();
      if (auth_user) {
        auth_user.getIdToken()
        .then((token) => {
          resolve(token);
        })
        .catch((err) => {
          reject(err);
        });
      }
      else {
        localStorage.removeItem('user');
        reject(new Error('No user is logged in.'));
      }
    });
  });
};

const api = axios.create({
  baseURL: import.meta.env.VITE_BASE_URL,
});

api.interceptors.request.use( (config) => {
  if (config.url.includes('/api/public')) {
    return config;
  }
  else {
    return getUserToken()
      .then((token) => {
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      })
      .catch((error) => {
        Promise.reject(error);
      });
    }
  },
  (error) => Promise.reject(error)
);

export default api;