/* eslint-disable react/prop-types */
import { useAuth } from './AuthContext';
import { Suspense, lazy } from 'react';

import { BrowserRouter, Routes, Route, Navigate} from'react-router-dom';

const Account = lazy(() => import('../pages/client/Account'));
const Discussion = lazy(() => import('../pages/client/Discussion'));
const Error = lazy(() => import('../pages/admin/Error'));
const Facilitator = lazy(() => import('../pages/client/Facilitator'));
const Guideline = lazy(() => import('../pages/client/Guideline'));
const Howto = lazy(() => import('../pages/client/Howto'));
const LandingHarvard = lazy(() => import('../pages/public/LandingHarvard'));
const LandingMGH = lazy(() => import('../pages/public/LandingMGH'));
const Login = lazy(() => import('../pages/public/Login'));
const Logout = lazy(() => import('../pages/public/Logout'));
const Matching = lazy(() => import('../pages/admin/Matching'));
const Orientation = lazy(() => import('../pages/client/Orientation'));
const Overview = lazy(() => import('../pages/client/Overview'));
const PostProgram = lazy(() => import('../pages/client/PostProgram'));
const ProgramEval = lazy(() => import('../pages/client/ProgramEval'));
const Reflection = lazy(() => import('../pages/client/Reflection'));
const Report = lazy(() => import('../pages/admin/Report'));
const Resource = lazy(() => import('../pages/client/Resource'));
const ResetPassword = lazy(() => import('../pages/public/ResetPassword'));
const Settings = lazy(() => import('../pages/admin/Settings'));
const Signup = lazy(() => import('../pages/public/Signup'));
const Survey = lazy(() => import('../pages/admin/Survey'));
const UserView = lazy(() => import('../pages/admin/UserView'));

const Admin = lazy(() => import('../pages/admin/Admin'));
const Home = lazy(() => import('../pages/client/Home'));

const AdminBar = lazy(() => import('../pages/admin/AdminBar'));
const UserBar = lazy(() => import('../pages/client/UserBar'));
const Loader = lazy(() => import('../components/Loader'));

const AuthenticatedRoute = ({ element }) => {
  const {user, loading} = useAuth();
  if (user === null && loading === true) return <Loader />;
  else if (user?.role === 'admin' || user?.role === 'facilitator') {
    return (
      <>
        <AdminBar />
        {element}
      </> 
    )
  }
  else if (user?.role === 'peer') {
    return (
      <>
        <UserBar />
        {element}
      </> 
    )
  }
  else return <Navigate to="/login" />;
};

const AdminRoute = ({ element }) => {
  const {user, loading} = useAuth();
  if (user === null && loading === true) return <Loader />;
  else if (user?.role === 'admin' || user?.role === 'facilitator') {
    return (
      <>
        <AdminBar />
        {element}
      </> 
    )
  }
  else return <Navigate to="/login" />;
};
          

export default function ConvuRouter() {
  const {user} = useAuth();

  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={user ? <Navigate to="/" /> : <Login />} />
          <Route path="/resetPassword" element={<ResetPassword />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/mgh" element={<LandingMGH />} />
          <Route path="/harvard" element={<LandingHarvard />} />
          
          <Route path="/" element={ (user?.role === 'admin' || user?.role === 'facilitator') ? <AdminRoute element={<Admin /> }/> : (user?.role === 'peer' ? <AuthenticatedRoute element={<Home /> }/> : <Login />) } />
          <Route path="/account" element={<AuthenticatedRoute element={<Account />} />} />
          <Route path="/discussion" element={<AuthenticatedRoute element={<Discussion />} />} />
          <Route path="/facilitators" element={<AuthenticatedRoute element={<Facilitator />} />} />
          <Route path="/guidelines" element={<AuthenticatedRoute element={<Guideline />} />} />
          <Route path="/howto" element={<AuthenticatedRoute element={<Howto />} />} />
          <Route path="/matching" element={<AdminRoute element={<Matching />} />} />
          <Route path="/orientation" element={<AuthenticatedRoute element={<Orientation />} />} />
          <Route path="/overview" element={<AuthenticatedRoute element={<Overview />} />} />
          <Route path="/post-program" element={<AuthenticatedRoute element={<PostProgram />} />} />
          <Route path="/program-eval" element={<AuthenticatedRoute element={<ProgramEval />} />} />
          <Route path="/reflection" element={<AuthenticatedRoute element={<Reflection />} />} />
          <Route path="/report" element={<AdminRoute element={<Report />} />} />
          <Route path="/resources" element={<AuthenticatedRoute element={<Resource />} />} />
          <Route path="/settings" element={<AdminRoute element={<Settings />} />} />
          <Route path="/survey" element={<AdminRoute element={<Survey />} />} />
          <Route path="/user-view" element={ (user?.role === 'admin' || user?.role === 'facilitator') ? <><AdminBar /><UserView /></> : <Login /> } />

          <Route path="*" element={<Error />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};