import SnackbarAlert from "../components/SnackbarAlert";

import {useState, useEffect, createContext, useContext } from "react";
import api from "./Axios";
import { auth } from "./firebaseInit";

const AuthContext = createContext();

export const AuthProvider = ({children}) => {
  const [alert, setAlert] = useState({open: false});
  //const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [events, setEvents] = useState([]);
  const [announcements, setAnnouncements] = useState([]);
  const [certReqs, setCertReqs] = useState({
    kickoff: {min: 0, max: 0},
    check_in: {min: 0, max: 0},
    completion: {min: 0, max: 0},
    reflection: {min: 0, max: 0},
    office_hours: {min: 0, max: 0},
    program_eval: 'no'
  });
  localStorage.removeItem('user');

  useEffect( () => {
    const unsubscribe = auth.onAuthStateChanged( async (auth_user) => {
    if (auth_user) {
      //if (auth_user.emailVerified) {
        if (!user) {
          api.get("/api/user/user")
          .then( async (user_doc) => {
            const user_data = user_doc.data;

            const time = new Date().getTime();
            if (!user_data.last_login) user_data.last_login = [];
            user_data.last_login = [time, ...user_data.last_login];
            
            if (user_data.last_login.length > 100) {
              user_data.last_login = user_data.last_login.slice(0, 100);
            }
            // delete all logins older than a year
            if (time - user_data.last_login[user_data.last_login.length - 1] > 365 * 24 * 60 * 60 * 1000) {
              let index = user_data.last_login.length - 1;
              for (let i = user_data.last_login.length - 1; i >= 0; i--) {
                if (time - user_data.last_login[i] > 365 * 24 * 60 * 60 * 1000) index = i;
              }
              user_data.last_login = user_data.last_login.slice(0, index);
            }

            if (!user_data.id || !user_data.last_login) {
              console.log('error with user id or last login');
              setAlert({open: true, message: 'There was a user authentication issue.', type: 'error'});
              setLoading(false);
              return;
            }

            /*
            console.log('about to check documents...');
            api.get("/api/user/cohort_group_check", {
              params: {
                cohort: user_data.current_cohort,
                group: user_data.current_group,
                role: user_data.role
              }
            })
            .then((res) => {
              console.log('Cohort and group check:', res.data);
            })
            .catch((err) => {
              console.log('Error: ' + req.url + ': ' + err.toString());
              user_data.optout = true;
              setUser(user_data);
              setAlert({open: true, message: 'There was a user authentication issue.', type: 'error'});
              setLoading(false);
              return;
            });
            */

            api.put("/api/shared/user_info", {
              id: user_data.id,
              key: 'last_login',
              value: user_data.last_login
            })
            .then( async () => {
              if (user_data.role === 'admin') {
                api.get("/api/admin/groups")
                .then((groups) => {
                  user_data.group = groups.data;
                  let cohorts = [];
                  user_data.group.forEach((group) => {
                    group.cohorts.forEach((cohort, idx) => {
                      group.cohorts[idx].group = group.id;
                    })
                    cohorts = cohorts.concat(group.cohorts);
                  })
                  user_data.cohort = cohorts;
                  setUser(user_data);
                  localStorage.setItem('user', JSON.stringify(user_data));
                  setLoading(false);
                })
                .catch ((err) => {
                  setAlert({open: true, message: 'Error getting groups', type: 'error'});
                })
              }
              else {
                setUser(user_data);
                //console.log('i just set regular user data');
                localStorage.setItem('user', JSON.stringify(user_data));
                setLoading(false);
              }
            })
            .catch ((err) => {
              setAlert({open: true, message: 'Error getting user info', type: 'error'});
            });
          })
          .catch ((err) => {
            // cannot get user info from server even though user is verified
            setLoading(false);
            //console.log('Error getting user info:', err);
            //setAlert({open: true, message: 'There was a user authentication issue.', type: 'error'});
          });
        }
        else {
          // user !== null
          setLoading(false);
        }
      //} else {
        // no verified email (email is sent via login page)
      //  setLoading(false);
      //}
    } else {
      // no user account or not logged in
      localStorage.removeItem('user');
      setLoading(false);
    }
  });

  return () => {
    unsubscribe();
  }

  }, []);

  // get users and facilitator events
  useEffect( () => {
    const fetchUsers = async () => {
      api.get("/api/facilitator/users", {
        params: {
          cohort: user.current_cohort
        }
      })
      .then(async (users_data_raw) => {
        let users_data = users_data_raw.data;
        if (users_data_raw.data.length === 0) {
          setUsers([]);
        }
        else {
          users_data = users_data.filter((x) => x.role === 'peer').sort((a, b) => a.last_name.toLowerCase().localeCompare(b.last_name.toLowerCase()));
          setUsers(users_data);

          let ls_data = {
            id: user.current_cohort,
            data: users_data,
            date: new Date(),
            hours: 1
          }
          localStorage.setItem('users', JSON.stringify(ls_data));
        }

        api.get("/api/user/events", {
          params: {
            cohort: user.current_cohort,
            group: user.current_group
          }
        })
        .then((cohort_events) => {
          const now = new Date();
          if (cohort_events.data.length > 0) {
            cohort_events.data.forEach((event) => {
              if (new Date(event.start_date).getTime() > now.getTime()) event.color = 'green';
              else event.color = 'grey';
              if (event.type === 'deadline') {
                event.color = 'yellow';
              }

              let names_string = '';
              let names = [];
              if ('participants' in event && event.participants && users_data.length > 0) {
                event.participants.forEach((person) => {
                  const index = users_data.findIndex((x) => x.id === person);
                  if (index > -1) {
                    names.push(users_data[index].name);
                    names_string += users_data[index].name + ", ";
                  }
                });
                if (names_string.length > 0) {
                  names_string = names_string.slice(0, names_string.length - 2);
                }
              }
          
              event.names_string = names_string;
              event.names = names;
            });
            setEvents(cohort_events.data);
          }
        })
        .catch((err) =>{
          setAlert({open: true, message: 'Error getting events', type: 'error'});
          setEvents([]);
        })
      })
      .catch((err) =>{
        setAlert({open: true, message: 'Error getting users', type: 'error'});
        setUsers([]);
      })
    };
    
    if (user && (user.role === 'facilitator' || user.role === 'admin')) {
      if (!user.current_cohort || !user.current_group) {
        setAlert({open: true, message: 'Error fetching users', type: 'error'});
        return;
      }
      else {
        fetchUsers();
      }
    }
  }, [user?.current_cohort, user?.current_group]);

  // get announcements
  useEffect(() => {
    const fetchAnnouncements = async () => {
      api.get("/api/user/announcements", {
        params: {
          cohort: user.current_cohort,
          group: user.current_group
        }
      })
      .then((cohort_announcements) => {
        if (user.role === 'peer') {
          let temp_announcements = [];
          cohort_announcements.data.forEach((item) => {
            if (item.display === 'manual' && item.visible === 'yes') {
              temp_announcements.push(item);
            }
            if (item.display === 'timed') {
              let now = new Date();
              if (new Date(item.start_date).getTime() <= now.getTime() && new Date(item.end_date).getTime() >= now.getTime() ) {
                temp_announcements.push(item);
              }
            }
          })

          setAnnouncements(temp_announcements);
        }
        else {
          setAnnouncements(cohort_announcements.data);
        }
      })
      .catch ((err) => {
        setAlert({open: true, message: 'Error getting announcements', type: 'error'});
        setAnnouncements([]);
      })
    };
    
    if (user) {
      if (!user.current_cohort || !user.current_group) {
        setAlert({open: true, message: 'Error fetching announcements', type: 'error'});
        return;
      }
      else {
        fetchAnnouncements();
      }
    }
    
  }, [user?.current_cohort, user?.current_group]);

  // get user events
  useEffect(() => {

    const getEventData = async () => {
      api.get("/api/user/events", {
        params: {
          cohort: user.current_cohort,
          group: user.current_group
        }
      })
      .then(async (res_events) => {
        let event_data = res_events.data;

        api.get("/api/shared/activities", {
          params: {
            id: user.id
          }
        })
        .then((res_activities) => {
          let activity_data = res_activities.data;

          const now = new Date();

          event_data.forEach((event) => {
            if (event.type === 'deadline') event.color = "yellow";
            else event.color = "grey";
          })

          for (let i = 0; i < activity_data.length; i++) {
            for (let j = 0; j < event_data.length; j++) {
              if (activity_data[i].id === event_data[j].id) {
                event_data[j].status = activity_data[i].status;
                if (activity_data[i].status === "attended") {
                  event_data[j].color = "blue";
                }
                else if (activity_data[i].status === "registered" && new Date(event_data[j].end_date).getTime() + (60 * 60 * 1000) < now.getTime()) {
                  event_data[j].color = "red";
                  event_data[j].status = "absent";
                }
                else if (activity_data[i].status === "registered" && new Date(event_data[j].start_date).getTime() < now.getTime() && new Date(event_data[j].end_date).getTime() + (60 * 60 * 1000) > now.getTime() ) {
                  event_data[j].color = "orange";
                }
                else if (activity_data[i].status === "registered") {
                  event_data[j].color = "green";
                }
              }
            }
          }

          event_data = event_data.filter(item => new Date(item.end_date).getTime() > now.getTime() || 'status' in item );

          setEvents(event_data);
        })
        .catch((err) => {
          setAlert({open: true, message: 'Problem parsing events', type: 'error'});
        })
      })
      .catch((err) => {
        setAlert({open: true, message: 'Problem getting events', type: 'error'});
      })
    }

    if (user && user.role === 'peer') {
      if (!user.current_cohort || !user.current_group || !user.id) {
        setAlert({open: true, message: 'Error fetching events', type: 'error'});
        return;
      }
      else {
        getEventData();
      }
    };

  }, [user?.current_cohort, user?.current_group, user?.id]);

  // get cert_req
  useEffect(() => {
    const fetchCertReqs = async () => {
      api.get("/api/user/setting", {
        params: {
          group: user.current_group,
          setting: 'certificate'
        }
      })
      .then((cert) => {
        let temp_cert = cert.data;
        Object.keys(temp_cert).forEach((key) => {
          if (!(key === 'program_eval' || key === 'title')) {
            temp_cert[key].min = parseInt(temp_cert[key].min);
            temp_cert[key].max = parseInt(temp_cert[key].max);
          }
        });
        setCertReqs(temp_cert);
      })
      .catch ((err) => {
        setAlert({open: true, message: 'Error getting certificate requirements', type: 'error'});
      })
    };
    
    if (user) {
      if (!user.current_group) {
        setAlert({open: true, message: 'Error fetching certificate requirements', type: 'error'});
        return;
      }
      else {
        fetchCertReqs();
      }
    }
  }, [user?.current_group]);

  return (
    <div>
      <SnackbarAlert data={alert} />
      <AuthContext.Provider
        value={{user, setUser, users, setUsers, events, setEvents, announcements, setAnnouncements, loading, certReqs}}
      >
        {children}
      </AuthContext.Provider>
    </div>
  );
};

export const useAuth = () => useContext(AuthContext);